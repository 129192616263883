<template>
  <v-form ref="patientStepOneForm" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="5">
          <p class="font-weight-medium mb-0">
            {{ $vuetify.lang.t("$vuetify.main.patientReg.stepOne.yourName") }}
          </p>
          <p class="text--gotham-book text-gray">
            {{ $vuetify.lang.t("$vuetify.main.patientReg.stepOne.nameDesk") }}
          </p>
        </v-col>
        <v-col cols="12" md="4" class="pt-1">
          <v-text-field
            v-model="patientName"
            :rules="patientNameRules"
            :label="
              $vuetify.lang.t(`$vuetify.main.patientReg.stepOne.nameLabel`)
            "
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <p class="font-weight-medium mb-0">
            {{ $vuetify.lang.t("$vuetify.main.patientReg.stepOne.yourAge") }}
          </p>
          <p class="text--gotham-book text-gray">
            {{ $vuetify.lang.t("$vuetify.main.patientReg.stepOne.ageDesk") }}
          </p>
        </v-col>
        <v-col cols="12" md="2" class="pt-1">
          <v-text-field
            v-model="patientAge"
            :rules="patientAgeRules"
            :label="
              $vuetify.lang.t(`$vuetify.main.patientReg.stepOne.ageLabel`)
            "
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <p class="font-weight-medium mb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.patientReg.stepOne.therapyExperience"
              )
            }}
          </p>
        </v-col>
        <v-col cols="12" md="2" class="pt-0 mt-0">
          <v-select
            v-model="therapyExperience"
            :items="therapyExperienceList"
            item-value="val"
            item-text="title"
            dense
            class="pt-1 mt-0"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-5"></v-divider>

      <v-row>
        <v-col cols="12" md="5">
          <p class="font-weight-medium mt-5">E-mail</p>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="patientEmail"
            :rules="patientEmailRules"
            label="E-mail"
            required
            class="mt-0 pt-2"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5">
          <p class="font-weight-medium mt-5">
            {{ $vuetify.lang.t("$vuetify.main.patientReg.stepOne.timeZone") }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <timeZoneInput @new-time-zone="setTimeZone"></timeZoneInput>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import TimeZoneInput from "@/components/UI/timeZoneInput";
export default {
  name: "stepOne",
  components: { TimeZoneInput },
  data() {
    return {
      valid: false,
      patientName: "",
      patientAge: "",
      patientEmail: "",
      therapyExperience: "",
      timeZone: "",
      patientNameRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientNameRules"
          ),
      ],
      patientAgeRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientAgeRules1"
          ),
        (v) =>
          +v > 16 ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientAgeRules2"
          ),
      ],
      patientEmailRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientEmailRules1"
          ),
        (v) =>
          /.+@.+/.test(v) ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientEmailRules2"
          ),
      ],
    };
  },
  created() {
    this.$eventHub.$on("patient-step-one-form_validate-form", this.validate);
  },
  beforeDestroy() {
    this.$eventHub.$off("patient-step-one-form_validate-form");
  },
  computed: {
    therapyExperienceList() {
      return [
        {
          title: this.$vuetify.lang.t("$vuetify.main.patientReg.stepOne.yes"),
          val: 1,
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.patientReg.stepOne.no"),
          val: 0,
        },
      ];
    },
  },
  methods: {
    setTimeZone(val) {
      this.timeZone = val;
    },
    validate() {
      this.$refs.patientStepOneForm.validate();

      if (this.valid) {
        this.$store.dispatch(
          "user/setPatientQuestionnaireName",
          this.patientName
        );
        this.$store.dispatch(
          "user/setPatientQuestionnaireAge",
          this.patientAge
        );
        this.$store.dispatch(
          "user/setPatientQuestionnaireTherapyExp",
          this.therapyExperience
        );
        this.$store.dispatch("user/setEmail", this.patientEmail);
        this.$store.dispatch("user/setUserTimezone", this.timeZone);

        this.$eventHub.$emit("switch-patient-registration-tab", 2);
      }
    },
  },
};
</script>
